miniShop2.Message = {
    initialize: function () {
        miniShop2.Message.close = function () {
        };
        /*miniShop2.Message.show = function (message) {
            if (message != '') {
                alert(message);
            }
        };*/

        miniShop2.Message.show = function (message, options) {
            bsNotifyShow(message, options)
        }
    },
    success: function (message) {
        miniShop2.Message.show(message, {
            delay: 10000,
        });
    },
    error: function (message) {
        miniShop2.Message.show(message, {
            delay: 10000,
            type: 'danger'
        });
    },
    info: function (message) {
        miniShop2.Message.show(message, {
            delay: 10000
        });
    }
};